// --------------------------------------------
// Colors -------------------------------------
// Usage example: color(primary, main)
// --------------------------------------------
$color: (
	typography: (
		1: #FFFFFF,
		2: #768696,
		3: #9CACBC
	),
	bg: (
		1: #141516,
		2: #222424,
		3: #454C4C
	),
	primary: (
		1: #4353FF,
		2: #5D6AFF,
		3: #1024FF
 	),
	secondary: (
		1: #43F1FF,
		2: #76F5FF,
		3: #10EDFF
	)
);

// --------------------------------------------
// Typography ---------------------------------
// --------------------------------------------
$font__family: (
	base: '"Heebo", sans-serif', // font-family(base)
	heading: '"Titillium Web", sans-serif', // font-family(heading)
	code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace', // font-family(code)
	pre: '"Courier 10 Pitch", Courier, monospace' // font-family(pre)
);

$font__sizes: (
	alpha:   ( 48px, 58px, 0px ), // font-size, line-height, kerning (use '0' if don't want to output any kerning)
	beta:    ( 40px, 50px, -0.2px ),
	gamma:   ( 32px, 42px, -0.1px ),
	delta:   ( 24px, 34px, -0.1px ),
	epsilon: ( 20px, 32px, -0.1px ),
	zeta:    ( 18px, 30px, -0.1px ),
	eta:     ( 16px, 30px, -0.1px ),
	theta:   ( 14px, 20px, 0px )
);

$font__scale: (
	desktop: (                             // i.e. $breakpoint__m + $breakpoint__l (600 - 1024)
		1: map-get($font__sizes, alpha),   // H1
		2: map-get($font__sizes, beta),    // H2
		3: map-get($font__sizes, gamma),   // H3
		4: map-get($font__sizes, epsilon), // H4, H5, H6
		5: map-get($font__sizes, epsilon), // Body
		6: map-get($font__sizes, zeta),    // Text small
		7: map-get($font__sizes, eta),     // Text smaller (e.g. forms, buttons)
		8: map-get($font__sizes, theta)	   // Footer links
	),
	mobile: (                              // i.e. $breakpoint__xs + $breakpoint__s (up to 600)
		1: map-get($font__sizes, beta),    // H1
		2: map-get($font__sizes, gamma),   // H2
		3: map-get($font__sizes, delta),   // H3
		4: map-get($font__sizes, epsilon), // H4, H5, H6
		5: map-get($font__sizes, epsilon), // Body
		6: map-get($font__sizes, zeta),    // Text small
		7: map-get($font__sizes, eta),     // Text smaller (e.g. forms, buttons)
		8: map-get($font__sizes, theta)	   // Footer links
	)
);

$font__weight: (
	regular: 400, 	// font__weight(regular)
	medium: 500,	// font__weight(medium)
	semibold: 600,	// font__weight(semibold)
	bold: 700		// font__weight(bold)
);

// --------------------------------------------
// Structure ----------------------------------
// --------------------------------------------
$content__padding: (
	mobile: 16px,
	desktop:  24px
);
$container__width: 1080px;
$container__width-sm: 800px;
