html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #141516;
}

hr {
  height: 1px;
  background: #454c4c80;
  border: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 24px;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul, li > ol {
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 24px;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 24px;
  margin-left: 24px;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

figure {
  margin: 24px 0;
}

figcaption {
  padding: 8px 0;
  font-size: 16px;
  line-height: 30px;
}

img, svg {
  display: block;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 24px;
}

tr {
  border-bottom: 1px solid #454c4c;
}

th {
  text-align: left;
}

th, td {
  padding: 10px 16px;
}

th:first-child, td:first-child {
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}

html {
  font-size: 20px;
  line-height: 32px;
}

body {
  color: #768696;
  font-size: 1rem;
}

body, button, input, select, textarea {
  font-family: Heebo, sans-serif;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover, a:active {
  outline: 0;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  clear: both;
  color: #fff;
  font-family: Titillium Web, sans-serif;
  font-weight: 600;
}

h1, .h1 {
  letter-spacing: -.2px;
  font-size: 40px;
  line-height: 50px;
}

@media (min-width: 641px) {
  h1, .h1 {
    letter-spacing: 0;
    font-size: 48px;
    line-height: 58px;
  }
}

h2, .h2 {
  letter-spacing: -.1px;
  font-size: 32px;
  line-height: 42px;
}

@media (min-width: 641px) {
  h2, .h2 {
    letter-spacing: -.2px;
    font-size: 40px;
    line-height: 50px;
  }
}

h3, .h3, blockquote {
  letter-spacing: -.1px;
  font-size: 24px;
  line-height: 34px;
}

@media (min-width: 641px) {
  h3, .h3, blockquote {
    letter-spacing: -.1px;
    font-size: 32px;
    line-height: 42px;
  }
}

h4, h5, h6, .h4, .h5, .h6 {
  letter-spacing: -.1px;
  font-size: 20px;
  line-height: 32px;
}

@media (max-width: 640px) {
  .h1-mobile {
    letter-spacing: -.2px;
    font-size: 40px;
    line-height: 50px;
  }

  .h2-mobile {
    letter-spacing: -.1px;
    font-size: 32px;
    line-height: 42px;
  }

  .h3-mobile {
    letter-spacing: -.1px;
    font-size: 24px;
    line-height: 34px;
  }

  .h4-mobile, .h5-mobile, .h6-mobile {
    letter-spacing: -.1px;
    font-size: 20px;
    line-height: 32px;
  }
}

.text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6 {
  color: #fff !important;
}

.text-sm {
  letter-spacing: -.1px;
  font-size: 18px;
  line-height: 30px;
}

.text-xs {
  letter-spacing: -.1px;
  font-size: 16px;
  line-height: 30px;
}

h1, h2, .h1, .h2 {
  margin-top: 48px;
  margin-bottom: 16px;
}

h3, .h3 {
  margin-top: 36px;
  margin-bottom: 12px;
}

h4, h5, h6, .h4, .h5, .h6 {
  margin-top: 24px;
  margin-bottom: 4px;
}

p {
  margin-top: 0;
  margin-bottom: 24px;
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  color: #9cacbc;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  font-style: italic;
}

blockquote:before {
  content: "“";
}

blockquote:after {
  content: "”";
}

blockquote p {
  display: inline;
}

address {
  color: #768696;
  border: 1px solid #454c4c;
  border-width: 1px 0;
  margin: 0 0 24px;
  padding: 24px 0;
}

pre, pre h1, pre h2, pre h3, pre h4, pre h5, pre h6, pre .h1, pre .h2, pre .h3, pre .h4, pre .h5, pre .h6 {
  font-family: "Courier 10 Pitch", Courier, monospace;
}

pre, code, kbd, tt, var {
  background: #222424;
}

pre {
  max-width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  font-size: 16px;
  line-height: 30px;
  overflow: auto;
}

code, kbd, tt, var {
  padding: 2px 4px;
  font-family: Monaco, Consolas, Andale Mono, DejaVu Sans Mono, monospace;
  font-size: 16px;
}

abbr, acronym {
  cursor: help;
}

mark, ins {
  text-decoration: none;
}

small {
  letter-spacing: -.1px;
  font-size: 18px;
  line-height: 30px;
}

b, strong {
  font-weight: 700;
}

button, input, select, textarea, label {
  font-size: 20px;
  line-height: 32px;
}

.container, .container-sm {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 481px) {
  .container, .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.container {
  max-width: 1128px;
}

.container-sm {
  max-width: 848px;
}

.container .container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  position: absolute !important;
}

.screen-reader-text:focus {
  letter-spacing: -.1px;
  height: auto;
  width: auto;
  z-index: 100000;
  background-color: #141516;
  border: none;
  padding: 16px 36px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: block;
  top: 8px;
  left: 8px;
  box-shadow: 0 0 2px 2px #0009;
  clip: auto !important;
  color: #4353ff !important;
}

.list-reset {
  padding: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: #4353ff;
}

.text-secondary {
  color: #43f1ff;
}

.has-top-divider {
  position: relative;
}

.has-top-divider:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #454c4c80;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.has-bottom-divider {
  position: relative;
}

.has-bottom-divider:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #454c4c80;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-8 {
  margin: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.m-16 {
  margin: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.m-24 {
  margin: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.m-32 {
  margin: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-48 {
  margin: 48px;
}

.mt-48 {
  margin-top: 48px;
}

.mr-48 {
  margin-right: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.ml-48 {
  margin-left: 48px;
}

.m-56 {
  margin: 56px;
}

.mt-56 {
  margin-top: 56px;
}

.mr-56 {
  margin-right: 56px;
}

.mb-56 {
  margin-bottom: 56px;
}

.ml-56 {
  margin-left: 56px;
}

.m-64 {
  margin: 64px;
}

.mt-64 {
  margin-top: 64px;
}

.mr-64 {
  margin-right: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.ml-64 {
  margin-left: 64px;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.p-8 {
  padding: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.p-16 {
  padding: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.p-24 {
  padding: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.p-32 {
  padding: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.p-40 {
  padding: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.p-48 {
  padding: 48px;
}

.pt-48 {
  padding-top: 48px;
}

.pr-48 {
  padding-right: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pl-48 {
  padding-left: 48px;
}

.p-56 {
  padding: 56px;
}

.pt-56 {
  padding-top: 56px;
}

.pr-56 {
  padding-right: 56px;
}

.pb-56 {
  padding-bottom: 56px;
}

.pl-56 {
  padding-left: 56px;
}

.p-64 {
  padding: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.pr-64 {
  padding-right: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pl-64 {
  padding-left: 64px;
}

.sr .has-animations .is-revealing {
  visibility: hidden;
}

.input, .textarea {
  color: #768696;
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #454c4c;
}

.input::-moz-placeholder, .textarea::-moz-placeholder {
  color: #9cacbc;
}

.input::placeholder, .textarea::placeholder {
  color: #9cacbc;
}

.input:-ms-input-placeholder, .textarea:-ms-input-placeholder {
  color: #9cacbc;
}

.input:hover, .textarea:hover {
  border-color: #393f3f;
}

.input:active, .input:focus, .textarea:active, .textarea:focus {
  border-color: #4353ff;
  outline: none;
}

.input[disabled], .textarea[disabled] {
  cursor: not-allowed;
  background-color: #222424;
  border-color: #222424;
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  letter-spacing: -.1px;
  height: 48px;
  box-shadow: none;
  padding: 13px 16px;
  font-size: 16px;
  line-height: 20px;
}

.input .inline-input {
  width: auto;
  display: inline;
}

.textarea {
  min-width: 100%;
  resize: vertical;
  display: block;
}

.textarea .inline-textarea {
  width: auto;
  display: inline;
}

.field-grouped > .control:not(:last-child) {
  margin-bottom: 8px;
}

@media (min-width: 641px) {
  .field-grouped {
    display: flex;
  }

  .field-grouped > .control {
    flex-shrink: 0;
  }

  .field-grouped > .control.control-expanded {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .field-grouped > .control:not(:last-child) {
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.button {
  letter-spacing: -.1px;
  cursor: pointer;
  height: 48px;
  text-align: center;
  white-space: nowrap;
  background-color: #141516;
  border: none;
  justify-content: center;
  padding: 16px 36px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  display: inline-flex;
  color: #4353ff !important;
  text-decoration: none !important;
}

.button:active {
  outline: 0;
}

.button-shadow {
  position: relative;
}

.button-shadow:before {
  content: "";
  transition: box-shadow .15s;
  position: absolute;
  inset: 0;
  box-shadow: 0 8px 48px #4353ff7a;
}

.button-shadow:hover:before {
  box-shadow: 0 8px 48px #4353ff99;
}

.button-sm {
  height: 32px;
  padding: 8px 24px;
}

.button-sm.button-shadow:before {
  box-shadow: 0 4px 32px #4353ff7a;
}

.button-sm.button-shadow:hover:before {
  box-shadow: 0 4px 32px #4353ff99;
}

.button-primary {
  background: linear-gradient(65deg, #4353ff 0, #5d6aff 100%);
  transition: background .15s;
  color: #fff !important;
}

.button-primary:hover {
  background: linear-gradient(65deg, #4858ff 0, #626fff 100%);
}

.button-block {
  display: flex;
}

.site-header {
  padding: 24px 0;
  position: relative;
}

.site-header-inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.header-links, .header-links li {
  display: inline-flex;
}

.header-links a:not(.button) {
  letter-spacing: -.1px;
  color: #768696;
  text-transform: uppercase;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: none;
}

.header-links a:not(.button):hover, .header-links a:not(.button):active {
  color: #fff;
}

.hero {
  padding-top: 48px;
}

.hero-paragraph {
  margin-bottom: 32px;
}

.hero-illustration {
  display: none;
  position: relative;
}

.hero-bg, .hero-square, .hero-dots, .hero-line {
  transform-origin: 50%;
  position: absolute;
}

.hero-bg {
  top: -160px;
  left: 28px;
}

.hero-square-1 {
  top: -270px;
  left: 150px;
}

.hero-square-2 {
  top: -42px;
  left: 600px;
}

.hero-square-3 {
  top: 46px;
  left: 74px;
}

.hero-square-4 {
  top: 0;
  left: 250px;
}

.hero-square-5 {
  top: 108px;
  left: 633px;
}

.hero-square-6 {
  top: 140px;
  left: 185px;
}

.hero-square-7 {
  top: 336px;
  left: 600px;
}

.hero-square-8 {
  top: 350px;
  left: 193px;
}

.hero-dots-1 {
  top: -107px;
  left: 397px;
}

.hero-dots-2 {
  top: 65px;
  left: 202px;
}

.hero-dots-3 {
  top: 396px;
  left: 53px;
}

.hero-line-1 {
  top: 318px;
  left: 229px;
}

@media (min-width: 641px) {
  .hero {
    padding-top: 80px;
  }

  .hero-inner {
    display: flex;
  }

  .hero-copy {
    min-width: 512px;
    width: 512px;
    padding-right: 48px;
  }

  .hero-illustration {
    width: 528px;
    display: block;
  }
}

@media (max-width: 640px) {
  .hero-form {
    margin: 0 auto;
  }
}

.feature {
  padding-top: 16px;
  padding-bottom: 16px;
}

.features-wrap:first-child {
  margin-top: -16px;
}

.features-wrap:last-child {
  margin-bottom: -16px;
}

.features-inner {
  max-width: 400px;
}

.feature-header {
  align-items: center;
  display: flex;
}

@media (min-width: 641px) {
  .feature {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .features-wrap:first-child {
    margin-top: -24px;
  }

  .features-wrap:last-child {
    margin-bottom: -24px;
  }
}

.is-boxed {
  background: #1e1f21;
}

.body-wrap {
  min-height: 100vh;
  background: #141516;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 16px 48px #14151680;
}

main {
  flex: 1 0 auto;
}

.section-inner {
  padding-top: 64px;
  padding-bottom: 64px;
  position: relative;
}

@media (min-width: 641px) {
  .section-inner {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

.site-footer {
  letter-spacing: 0;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.site-footer a {
  color: #768696;
  text-decoration: none;
}

.site-footer a:hover, .site-footer a:active {
  color: #fff;
  text-decoration: underline;
}

.footer-bg, .footer-dots {
  display: none;
}

.site-footer-inner {
  flex-wrap: wrap;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  position: relative;
}

.footer-social-links, .footer-copyright {
  width: 100%;
  flex: none;
  justify-content: center;
  display: inline-flex;
}

.footer-copyright {
  margin-bottom: 24px;
}

.footer-social-links {
  margin-bottom: 0;
}

.footer-social-links li {
  display: inline-flex;
}

.footer-social-links li + li {
  margin-left: 16px;
}

.footer-social-links li a {
  padding: 8px;
}

@media (min-width: 641px) {
  .site-footer:before {
    height: 202px;
  }

  .footer-bg, .footer-dots {
    display: block;
    position: absolute;
  }

  .footer-bg {
    bottom: 0;
    right: 0;
  }

  .footer-dots {
    bottom: 124px;
    right: 127px;
  }

  .site-footer-inner {
    justify-content: space-between;
  }

  .footer-social-links, .footer-copyright {
    flex: 50%;
  }

  .footer-copyright {
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .footer-social-links {
    justify-content: flex-end;
  }
}

/*# sourceMappingURL=index.207f3331.css.map */
