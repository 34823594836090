.feature {
	padding-top: 16px;
	padding-bottom: 16px;
}

.features-wrap {

    &:first-child {
        margin-top: -16px;
    }

    &:last-child {
        margin-bottom: -16px;
    }
}

.features-inner {
	max-width: 400px;
}

.feature-header {
	display: flex;
	align-items: center;
}

@include media( '>medium' ) {

	.feature {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	.features-wrap {

	    &:first-child {
	        margin-top: -24px;
	    }

	    &:last-child {
	        margin-bottom: -24px;
	    }
	}
}
