.site-footer {
	position: relative;
    @include font-size(8, mobile, true, true, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, true, true);
        }
    }

    a {
        @include anchor-aspect(footer);
    }
}

.footer-bg,
.footer-dots {
	display: none;
}

.site-footer-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    flex-wrap: wrap;
	align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
}

.footer-social-links,
.footer-copyright {
    flex: none;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.footer-copyright {
	margin-bottom: 24px;
}

.footer-social-links {
	margin-bottom: 0;

    li {
		display: inline-flex;

        + li {
            margin-left: 16px;
        }

		a {
			padding: 8px;
		}
    }
}

@include media( '>medium' ) {

    .site-footer {

        &::before {
            height: 202px;
        }
    }

	.footer-bg,
	.footer-dots {
		display: block;
		position: absolute;
	}

	.footer-bg {
		bottom: 0;
		right: 0;
	}

	.footer-dots {
		bottom: 124px;
		right: 127px;
	}

    .site-footer-inner {
        justify-content: space-between;
    }

    .footer-social-links,
    .footer-copyright {
        flex: 50%;
    }

    .footer-copyright {
		margin-bottom: 0;
        justify-content: flex-start;
    }

    .footer-social-links {
        justify-content: flex-end;
    }
}
